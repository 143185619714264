import { useEffect, useState } from 'react'
import useFetch from 'use-http'
import useUser from 'hooks/useUser'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import Error from 'components/ui/Error'

const geocoder = new MapboxGeocoder({
  accessToken: process.env.REACT_APP_MB_TOKEN,
  mapboxgl: mapboxgl,
  types: 'address, locality, place, postcode',
  minLength: 8,
})

export default function UpdateLocation () {
  const [location, setLocation] = useState()
  const { post, error, response } = useFetch('/api/user/update')
  const { profile, mutate } = useUser()
  useEffect(() => {
    const parent = document.getElementById('default_location')
    if (parent.children.length === 0) {
      // check is in place as this mount repeatedly in development
      // geocoder needs a clean up function...
      // opened issue on gh repo ✅
      geocoder.addTo('#default_location')
      geocoder.on('result', function ({ result }) {
        setLocation(result)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  function requestGeolocation () {
    // TODO udpate DB to say location services are enabled
  }
  return (
    <div className='block p-8 pb-4 w-full'>
      <div className='border border-gray-100 rounded-md bg-gray-100 p-4'>
        <h2 className='font-bold text-xl'>Default Location</h2>
        <p>
          {profile?.location ? profile.location.place_name : 'This is the map center you see when you login'}
        </p>
        <div className='grid grid-cols-3 sm:grid-cols-6 my-2 gap-4'>
          <div id='default_location' className='w-full col-span-2 sm:col-span-5' />
          <button
            type='button'
            disabled={!location}
            className='col-span-1 w-full bg-emerald-500 disabled:bg-emerald-200'
            onClick={async () => {
              await post({ location })
              if (response.ok) {
                mutate(response.data)
              }
            }}
          >
            Save
          </button>
        </div>
        <div className='grid grid-cols-12 justify-items-center my-8'>
          <div className='col-span-5 w-full bg-gray-300 mt-3 h-0.5'></div>
          <p className='col-span-2 '>OR</p>
          <div className='col-span-5 w-full bg-gray-300 mt-3 h-0.5'></div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 mb-2 mt-4'>
          <button onClick={requestGeolocation} className='bg-violet-600 max-h-12'>Use Device's Location</button>
          <p className='sm:pl-6 py-2 sm:py-0'>
            Accept the prompt to share location data with Dispatchr.
            <br/>
            We don't store or share this information
          </p>
        </div>
        <Error error={error} />
        {response.ok ? <div className='card border-2 border-emerald-400 rounded-md shadow-sm p-4 block'>Updated!</div> : null}
      </div>
    </div>
  )
}
