import { Redirect } from 'react-router-dom'
import useSwr from 'swr'
import Spinner from 'components/ui/Loaders'
import Error from 'components/ui/Error'

export default function StripeVerify () {
  const { data, error } = useSwr('/api/stripe/verify')
  if (error) return <div className='p-4'><Error error={error} /></div>
  if (!data) return <div className='p-4'><Spinner color='gray' /></div>
  return (
    <Redirect
      to={{
        pathname: '/settings/account',
        state: {
          stripeAccount: data
        }
      }}
    />
  )
}
