import { IKImage } from 'imagekitio-react'

export default function ActiveAgent ({ agent }) {
  return (
    <div className='my-4 flex flex-col p-2 border w-full md:w-1/2 border-gray-200 rounded-lg'>
      <div className='flex flex-row items-center align-center justify-between'>
        <h4 className='text-lg'>Active Agent: <b>{agent?.username}</b></h4>
        <IKImage className='avatar mr-2' path={agent?.avatar} />
      </div>
    </div>
  )
}
