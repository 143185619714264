import { Form, Formik, Field } from 'formik'
import useFetch from 'use-http'
import useSWR from 'swr'
import ReactHtmlParser from 'react-html-parser'
import { IKImage } from 'imagekitio-react'
import useUser from 'hooks/useUser'
import Spinner, { ContentLoader } from 'components/ui/Loaders'
import MisionMarkers from 'img/markers'

export default function MissionDetail({ mission, setMissionDetail }) {
  const { post, response, loading, error } = useFetch('/api/missions/apply')
  const { data: dispatchr } = useSWR(mission ? '/api/users/profile/' + mission.postedBy : null)
  const { profile } = useUser()
  return !mission ? null : (
    <div className='block fixed z-10 min-h-screen w-screen bg-gray-100 bg-opacity-50 flex flex-col justify-center items-center'>
      <Formik
        initialValues={{
          message: '',
          missionId: mission._id,
        }}
        onSubmit={async (values) => {
          await post(values)
          if (response.ok) {
            setMissionDetail(null)
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <div className='p-4 cursos-default overflow-scroll md:overflow-hidden sm:mt-2 sm:align-middle w-screen sm:w-4/6 md:w-1/2' role='dialog' aria-modal='true'>
            <Form>
              <div className='flex justify-between items-center my-2'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  {mission.title}
                  <button
                    className='inline-flex items-center justify-center rounded-full bg-amber-50 text-amber-900 px-2 py-0.5 ml-2'
                  >
                    ${mission.bounty}
                  </button>
                </h3>
                <div className='w-6 h-6'>
                  {ReactHtmlParser(MisionMarkers[mission.icon || 'default'])}
                </div>
              </div>
              <div>
                <p className='my-2 max-w-2xl text-lg text-gray-500'>{mission.description}</p>
              </div>
              <div className='my-4 flex flex-col p-2 border w-full md:w-1/2 border-gray-200 rounded-lg'>
                {dispatchr ? <div className='flex flex-row items-center align-center justify-between'>
                  <h4 className='text-lg'>Posted by <b>{dispatchr.username}</b></h4>
                  <IKImage className='avatar mr-2' path={dispatchr.avatar || '/jenny_zc6tj_PDU.jpg'} />
                </div> : <ContentLoader />}
              </div>
              <div className='my-4'>
                <label htmlFor='message' className='block text-sm font-medium text-gray-700 my-2'>Note (optional)</label>
                <Field as='textarea' name='message' className='w-full focus:ring-indigo-500 focus:border-indigo-500' />
              </div>
              {error ?
                <div className='my-4'>
                  <p className='text-bold text-red-500'>{error.info || error.message || error}</p>
                </div>
              : null}
              <div className='sm:flex sm:flex-row-reverse justify-between'>
                <button
                  type='submit'
                  disabled={isSubmitting || mission.postedBy === profile._id}
                  className='w-full disabled:opacity-50 inline-flex justify-center rounded-md border border-transparent shadow-sm py-2 bg-green-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none sm:w-auto sm:text-sm'
                >
                  <Spinner loading={loading} />
                  Apply
                </button>
                <button
                  type='button'
                  onClick={() => setMissionDetail(null)}
                  className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  )
}
