import { Link } from 'react-router-dom'
import { Form, Formik, Field } from 'formik'
import useFetch from 'use-http'
import Div100vh from 'react-div-100vh'

import useUser from 'hooks/useUser'
import Spinner from 'components/ui/Loaders'
import Error from 'components/ui/Error'
import logo from 'img/logo-d.png'

export default function Login () {
  const { post, response, error } = useFetch('/api/account/login')
  const { mutate } = useUser()
  return (
    <Div100vh>
      <main className='h-full w-screen bg-emerald-500 bg-opacity-70 flex flex-col items-center justify-center p-4'>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async (values) => {
            await post(values)
            if (response.ok) {
              const { user } = response.data
              mutate(user)
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className='flex flex-col items-center justify-evenly h-96 card px-6 py-2'>
              <Link to='/'>
                <img className='h-24' src={logo} alt='Dispatchr Logo with text' />
              </Link>
              <Field type='email' name='email' placeholder='Email' />
              <Field type='password' name='password' placeholder='Password' />
              <Error classes='m-4' error={error} />
              <div className='flex flex-col w-full pt-4 pb-1 justify-between align-center items-center'>
                <button
                  disabled={isSubmitting}
                  className='bg-blue-500 mr-2 hover:bg-blue-600 focus:outline-none px-12'
                  type='submit'
                >
                  <Spinner loading={isSubmitting} />
                  Login
                </button>
                <Link to='/register' className='text-violet-600 mt-4'>Register</Link>
              </div>
            </Form>
          )}
        </Formik>
      </main>
    </Div100vh>
  )
}
