import useFetch from 'use-http'
import Spinner from 'components/ui/Loaders'
import Error from 'components/ui/Error'
import ButtonBg from 'img/stripe-button.png'

export default function StripeConnect () {
  const { post, data, error, loading } = useFetch('/api/stripe/connect')
  return (
    <div className='block px-8 pt-8 w-full'>
      <div className='grid grid-cols-3 border border-gray-100 rounded-md bg-gray-100 p-4 flex-col sm:flex-row'>
        <div className='col-span-2'>
          <h2 className='font-bold text-xl'>Get paid on Dispatchr</h2>
          <p>To receive payments for completing missions, connect a Stripe account</p>
        </div>
        <div onClick={async () => {
          await post()
          if (data) window.location.replace(data.url)
        }} className='col-span-1 cursor-pointer mt-4 sm:mt-0 h-8 flex justify-end'>
          {
            loading ? <Spinner color='gray' />
            : <img className='h-full w-auto' src={ButtonBg} alt='connect to stripe' />
          }
        </div>
      </div>
      {
        error ? (
          <Error error={error} classes='mt-4 block w-full' />
        ) : null
      }
    </div>

  )
}
