import { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { mutate } from 'swr'
import useFetch from 'use-http'
import ContentLoader from 'components/ui/Loaders'
import Error from 'components/ui/Error'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

function CreditCardForm ({ refresh }) {
  const [error, setError] = useState(null)
  const { post, response } = useFetch('/api/stripe/customer')
  const stripe = useStripe()
  const elements = useElements()

  function handleChange (event) {
    if (event.error) {
      setError(event.error.message)
    } else {
      setError(null)
    }
  }

  async function handleSubmit (event) {
    event.preventDefault()
    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card)
    if (result.error) {
      setError(result.error.message)
    } else {
      setError(null);
      await post({ token: result.token.id })
      if (response.ok) {
        mutate('/api/user/paymentMethods')
      }
    }
  }
  return (
    <>
      <div className='my-4 grid grid-cols-12 items-center'>
        <div className='col-span-10 pr-4'>
          <CardElement
            id='card-element'
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
        </div>
        <div className='col-span-2'>
          <button onClick={handleSubmit} className='bg-blue-500 w-full'>
            Save
          </button>
        </div>
      </div>
      {error ? <Error classes='my-4' error={error} /> : null}
    </>
  )
}

export default function CreditCardInput () {
  const [sp, setSP] = useState(false)
  useEffect(() => {
    (async () => {
      if (!sp) {
        const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PK)
        setSP(stripePromise)
      }
    })()
  }, [sp])
  return sp ? (
    <Elements stripe={sp}>
      <CreditCardForm />
    </Elements>
  ) : <ContentLoader />
}
