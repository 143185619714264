import { useContext, useState } from 'react'
import useSWR from 'swr'
import useUser from 'hooks/useUser'
import MarkerIcons from 'img/markers.js'
import MissionDetail from 'components/Map/MissionDetail'
import { AppLoader } from 'components/ui/Loaders'

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import 'styles/leaflet.css'

import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

import CreateModalContext from 'contexts/CreateModalContext'

const geocoder = new MapboxGeocoder({
  mapboxgl,
  accessToken: process.env.REACT_APP_MB_TOKEN,
  types: 'address, locality, place, postcode',
  minLength: 8,
})

const divIcon = {
  iconSize: 36,
  className: 'markerIconParent',
}

const meIcon = L.divIcon({
  ...divIcon,
  html: MarkerIcons['me'],
  className: 'markerIconParent me'
})

function GoHome ({ geo, location }) {
  const map = useMap()
  const [on, toggle] = useState(false)
  function handleClick () {
    if (on) {
      map.stopLocate()
    } else {
      map.locate({ watch: true })
    }
    toggle(!on)
  }
  return (
    <div className='h-8 w-8 geolocate markerIconParent currentLocation fixed top-6 right-5 z-30'>
      <svg className='markerIcon' onClick={handleClick} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d='M0 0l20 8-8 4-2 8z' />
      </svg>
    </div>
  )
}

function MapView({ location }) {
  const [lng, setLng] = useState(location?.center[0] || -97.743061)
  const [lat, setLat] = useState(location?.center[1] || 30.267153)
  const [zoom] = useState(13)
  const [geo, setGeo] = useState(false)
  const [missionDetail, setMissionDetail] = useState(null)
  const { toggleCreateModal, setMissionStart } = useContext(CreateModalContext)
  const { data: missions } = useSWR('/api/missions/get')
  return (
    <MapContainer
      whenCreated={
        map => {
          geocoder.on('result', function ({ result }) {
            setMissionStart(result)
            const center = [result.center[1], result.center[0]]
            const addMarkerLayer = L.layerGroup().addTo(map)
            const existingAddMarker = document.getElementById('toggleCreateModal')
            if (existingAddMarker) {
              existingAddMarker.parentNode.remove()
            }
            const addMarker = L.marker(center, {
              icon: L.divIcon({
                ...divIcon,
                html: MarkerIcons['add'],
              })
            }).on('add', function () {
              const addMarker = document.getElementById('toggleCreateModal')
              if (addMarker) {
                addMarker.addEventListener('click', () => {
                  this.remove()
                  toggleCreateModal(true)
                })
              }
            })
            addMarker.addTo(addMarkerLayer)
            map.flyTo(center, 14)
          })

          geocoder.addTo('#search')

          map.on('locationerror', function (error) {
            console.error(error)
          })

          map.on('locationfound', function (data) {
            setLng(data.longitude)
            setLat(data.latitude)
            setGeo(data)
          })
        }
      }
      className='h-full w-full'
      attributionControl={false}
      center={[lat, lng]}
      zoom={zoom}
      scrollWheelZoom={true}
      placeholder={<AppLoader />}
    >
      <TileLayer
        attribution={`Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`}
        url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
        id='mapbox/streets-v11'
        tileSize={512}
        zoomOffset={-1}
        accessToken={process.env.REACT_APP_MB_TOKEN}
      />
      <div id='search' className='absolute top-5 right-16'></div>
      <GoHome geo={geo} location={location} />
      <Marker position={[lat, lng]} icon={meIcon} />
      {missions ? missions.map(m => {
        const p = m.startLocation.center
        return (
          <Marker
            key={m._id}
            position={[p[1], p[0]]}
            eventHandlers={{
              click: () => {
                setMissionDetail(m._id)
              }
            }}
            icon={
              L.divIcon({
                ...divIcon,
                html: MarkerIcons[m.icon || 'default'],
              })
            }
          />
        )
      }) : null}
      <MissionDetail mission={missions?.find(m => m._id === missionDetail)} setMissionDetail={setMissionDetail} />
    </MapContainer>
  )
}

export default function MapWrapper () {
  const { profile, error, loading } = useUser()
  if (loading) return <AppLoader />
  if (error) return <p>Error!</p>
  if (profile) return <MapView location={profile.location} />
}
