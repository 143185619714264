import useSWR from 'swr'
import Payment from 'components/ui/Payment'
import Error from 'components/ui/Error'
import { ContentLoader } from 'components/ui/Loaders'

export default function Payments () {
  const { data: payments, error } = useSWR('/api/user/payments')
  if (error) return <Error error={error} />
  return !payments ? <ContentLoader classes='m-4' /> : (
    <>
      {error ? <Error error={error} /> : null}
      {!payments ? <ContentLoader classes='m-4' /> : (
        <div className='p-4'>
          {payments.length ? payments.map(payment => <Payment payment={payment} />) : (
            <div className='card p-2 rounded-md text-center'>

              <h4 className='font-bold text-lg'>
                <span className='text-5xl' role='img' aria-label='flying money emoji'>💸</span>
                <br />
                No payments yet, time to get busy!
              </h4>
            </div>
          )}
        </div>
      )}
    </>
  )
}
