import { useContext } from 'react'
import { Form, Formik, Field } from 'formik'
import dayjs from 'dayjs'
import { mutate } from 'swr'
import useFetch from 'use-http'
import ReactHtmlParser from 'react-html-parser';
import CreateModalContext from 'contexts/CreateModalContext'
import Autocomplete from 'components/form/Autocomplete'
import MarkerIcons from 'img/markers.js'

export default function CreateMissionModal() {
  const { open, toggleCreateModal, start } = useContext(CreateModalContext)
  const { post, response, loading, error } = useFetch('/api/missions/create')
  const mission = {
    title: '',
    bounty: '',
    image: '',
    description: '',
    icon: '',
    startLocation: start || '',
    endLocation: undefined,
    waypoints: [],
    online: false,
    live: true,
    date: '',
    time: '',
  }

  return (
    <div className={`${open ? 'block fixed' : 'hidden'} z-20 min-h-screen w-screen bg-gray-100 bg-opacity-50 flex flex-col justify-center items-center`}>
      <Formik
        enableReinitialize={true}
        initialValues={mission}
        onSubmit={async (values) => {
          const { date, time, ...rest } = values
          await post(Object.assign({}, rest, values.live ? {} : { start: dayjs(`${date}:${time}`, 'YYYY-MM-DD:HH').format() }))
          if (response.ok) {
            mutate('/api/missions/get', async missions => [ ...missions, response.data ])
            toggleCreateModal(false)
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <div className='overflow-scroll md:overflow-hidden sm:mt-2 sm:align-middle w-screen sm:w-4/6 md:w-1/2' role='dialog' aria-modal='true'>
            <Form>
              <div className='bg-white flex flex-col justify-evenly p-4'>
                <h2 className='text-2xl font-bold'>Post a new mission <span role='img' aria-label='thunder emoji'>⚡</span></h2>
                <hr className='mb-2 mt-4' />
                <div>
                  <label htmlFor='title' className='block text-sm font-medium text-gray-700 my-2'>Title</label>
                  <Field type='text' className='mb-4 focus:ring-indigo-500 focus:border-indigo-500' id='title' name='title' placeholder='Win the world' />
                </div>
                <div>
                  <label htmlFor='title' className='block text-sm font-medium text-gray-700 my-2'>Description</label>
                  <Field className='mb-4 w-full focus:ring-indigo-500 focus:border-indigo-500' id='description' name='description' placeholder='By any means necessary' rows={2} as='textarea' />
                </div>
                <div className='grid grid-cols-12 grid-rows-1 gap-4'>
                  <div className='col-span-5'>
                    <label htmlFor='icon' className='block text-sm font-medium text-gray-700 my-2'>Mission Type</label>
                    <Field id='icon' className='md:mb-4 col-span-5 w-full' name='icon' as='select'>
                      <option value='admin'>Admin</option>
                      <option value='cart'>Shopping</option>
                      <option value='dolly'>Moving</option>
                      <option value='camera'>Photo/video</option>
                      <option value='food'>Food prep/delivery</option>
                      <option value='laptop'>Digital</option>
                      <option value='personal'>Personal</option>
                      <option value='home'>General Labour</option>
                      <option value='academy'>Tutoring</option>
                      <option value='creative'>Creative</option>
                      <option value='cog'>Trades</option>
                    </Field>
                  </div>
                  <div className='col-span-2'>
                    <label htmlFor='markerIcon' className='block text-sm font-medium text-gray-700 my-2'>Marker</label>
                    <div className='w-8 h-8 flex justify-center align-center' id='markerIcon'>
                      {ReactHtmlParser(MarkerIcons[values.icon || 'admin'])}
                    </div>
                  </div>
                  <div className='col-span-5'>
                    <label htmlFor='bounty' className='block text-sm font-medium text-gray-700 my-2'>Bounty</label>
                    <Field className='mb-4 w-full' name='bounty' id='bounty'>
                      {({ field }) => (
                        <div className='flex flex-row rounded-md shadow-sm'>
                          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                            $
                          </span>
                          <input {...field} type='text' name='bounty' id='bounty' className='grouped focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-none rounded-r-md sm:text-sm border-gray-300' placeholder='0.00' />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className='grid grid-cols1 sm:grid-cols-12 grid-rows-1 gap-4'>
                  <div className='col-span-8'>
                    <label htmlFor='location' className='block text-sm font-medium text-gray-700 my-2'>Start Location</label>
                    <Autocomplete start={start} />
                  </div>
                  <div className='col-span-4 flex flex-row items-center md:mt-4 mb-2 md:mb-0'>
                    <label htmlFor='online' className='block text-sm mr-2 font-medium text-gray-700 my-2'>Online</label>
                    <Field type='checkbox' name='online' id='online' />
                  </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-4 w-full'>
                  <div>
                    <label htmlFor='date' className='block text-sm font-medium text-gray-700 my-2'>Start Date</label>
                    <Field type='date' disabled={values.live} className='md:mb-4 w-full disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500' name='date' id='date' />
                  </div>
                  <div>
                    <label htmlFor='time' className='block text-sm font-medium text-gray-700 my-2'>Start Time</label>
                    <Field type='time' disabled={values.live} className='md:mb-4 w-full disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500' name='time' id='time' />
                  </div>
                  <div className='flex flex-row items-center md:mt-4'>
                    <label htmlFor='live' className='block text-sm mr-2 font-medium text-gray-700 my-2'>Starts Now!</label>
                    <Field type='checkbox' name='live' id='live' />
                  </div>
                </div>
              </div>
              {error ?
                <div>
                  <p>{error.info || error.message || error}</p>
                </div>
              : null}
              <div className='bg-gray-50 p-4 sm:flex sm:flex-row-reverse justify-between'>
                <button type='submit' disabled={isSubmitting} className='w-full disabled:opacity-50 inline-flex justify-center rounded-md border border-transparent shadow-sm py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none sm:w-auto sm:text-sm'>
                  {loading ? '...' : 'Dispatch!'}
                </button>
                <button type='button' onClick={() => toggleCreateModal(!open)} className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'>
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  )
}
