import useSWR from 'swr'

export default function useUser() {
  const { data, mutate, error } = useSWR('/api/user/profile')

  const loading = !data && !error
  const loggedOut = data === 'Unauthorized' || error?.status === 403 || error?.status === 401

  return {
    loading,
    loggedOut,
    error,
    profile: data,
    mutate
  };
}
