import { useState } from 'react'
import { Link, useLocation, useHistory, Redirect, Route, Switch } from 'react-router-dom'
import useUser from 'hooks/useUser'
import Spinner from 'components/ui/Loaders'
import Account from 'components/form/Account'
import Profile from 'components/form/Profile'
import PaymentMethods from 'components/Account/PaymentMethods'
import Payments from 'components/Account/Payments'

function PaymentsPage () {
  return (
    <>
      <PaymentMethods />
      <Payments />
    </>
  )
}

export default function Settings () {
  const location = useLocation()
  const history = useHistory()
  const [loading, toggleLoading] = useState(false)
  const { mutate } = useUser()
  return (
      <div className='h-full w-screen grid grid-rows-32'>
        <nav className='w-screen inline-flex row-span-3 relative h-full z-0 shadow-sm -space-x-px bg-white'>
          <Link
            to='/settings/profile'
            className={`tab w-1/3 ${location.pathname.includes('profile') && 'active'}`}
          >
            <p className='w-full text-center font-bold'>Profile</p>
          </Link>
          <Link
            to='/settings/payments'
            className={`tab w-1/3 ${location.pathname.includes('payments') && 'active'}`}
          >
            <p className='w-full text-center font-bold'>Payments</p>
          </Link>
          <Link
            to='/settings/account'
            className={`tab w-1/3 ${location.pathname.includes('account') && 'active'}`}
          >
            <p className='w-full text-center font-bold'>Account</p>
          </Link>
        </nav>
        <div className={`w-screen h-full overflow-y-scroll ${location.pathname.includes('profile') ? 'row-span-27' : 'row-span-29'}`}>
          <Switch>
            <Route path='/settings/profile' component={Profile} exact />
            <Route path='/settings/payments' component={PaymentsPage} exact />
            <Route path='/settings/account' component={Account} exact />
            <Redirect to='/settings/profile' />
          </Switch>
        </div>
        {
          location.pathname.includes('profile') ?
            <button
              className='button row-span-2 rounded-none bg-red-300 hover:bg-opacity-90 min-w-full block'
              onClick={async () => {
                toggleLoading(true)
                await fetch('/api/account/logout')
                mutate(null)
                history.push('/')
              }}
            >
              {loading ? <Spinner /> : 'Logout'}
            </button>
          : null
        }
      </div>
  )
}
