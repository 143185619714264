import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import CreateModalContext from 'contexts/CreateModalContext'

export default function Nav() {
  const history = useHistory()
  const { open, toggleCreateModal } = useContext(CreateModalContext)
  return (
    <div className='flex flex-row w-full row-span-1 bg-gray-100 justify-center items-center z-10'>
      <NavLink
        activeClassName='active-nav'
        className='w-1/5 sm:w-1/6 flex justify-center items-center text-black text-center focus:outline-none'
        to='/map'
      >
        <div className='text-sm sm:text-md font-extrabold'>
          <svg className='m-auto h-10 w-10 sm:h-12 sm:w-12' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7' />
          </svg>
          Map
        </div>
      </NavLink>
      <NavLink
        activeClassName='active-nav'
        className='w-1/5 sm:w-1/6 flex justify-center items-center text-black text-center focus:outline-none'
        to='/missions'
      >
        <div className='text-sm sm:text-md font-extrabold'>
          <svg className='m-auto h-10 w-10 sm:h-12 sm:w-12' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M13 10V3L4 14h7v7l9-11h-7z' />
          </svg>
          Missions
        </div>
      </NavLink>
      <div className='w-1/5 sm:w-1/6 text-sm sm:text-md font-extrabold flex justify-center items-center'>
        <button
          className='hover-grow rounded-full bg-emerald-500 hover:bg-opacity-90 hover:shadow-sm p-3'
          onClick={() => {
            history.push('/map')
            toggleCreateModal(!open)
          }}>
          <svg className='m-auto h-10 w-10 sm:h-12 sm:w-12' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={3} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
          </svg>
        </button>
      </div>
      <NavLink
        activeClassName='active-nav'
        className='w-1/5 sm:w-1/6 flex justify-center items-center text-black text-center focus:outline-none'
        to='/inbox'
      >
        <div className='text-sm sm:text-md font-extrabold'>
          <svg className='m-auto h-10 w-10 sm:h-12 sm:w-12' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z' />
          </svg>
          Messages
        </div>
      </NavLink>
      <NavLink
        activeClassName='active-nav'
        className='w-1/5 sm:w-1/6 flex justify-center items-center text-black text-center focus:outline-none'
        to='/settings'
      >
        <div className='text-sm sm:text-md font-extrabold'>
          <svg className='m-auto h-10 w-10 sm:h-12 sm:w-12' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
          </svg>
          Profile
        </div>
      </NavLink>
    </div>
  )
}
