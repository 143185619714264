import useUser from 'hooks/useUser'
import Spinner from 'components/ui/Loaders'
import { IKImage } from 'imagekitio-react'

function Message ({ message, author, userid }) {
  return (
    <div className={`${author._id === userid ? 'text-right' : 'text-left'} p-4 w-full`}>
      <p>{message.body}</p>
      <small className='text-xs'>{message.sent}</small>
    </div>
  )
}

function Thread ({ thread }) {
  const { profile } = useUser()
  return (
    <div className='card grid grid-cols-12 shadow border border-gray-300 '>
      <div className='col-span-2 border-r border-gray-200'>
        {thread.participants.map(u => u._id === profile._id ? null :  (
          <div className='p-2' key={u._id}>
            <div className='flex flex-col items-center'>
              <IKImage className='avatar' path={u.avatar} alt='conversation participant avatar' />
              <span className='mt-1'>{u.username}</span>
            </div>
          </div>
        ))}
      </div>
      <div className='col-span-10 divide-y divide-gray-200'>
        {thread.messages.map((message, index) => (
          <Message
            message={message}
            author={thread.participants.find((p) => p._id === message.from)}
            userid={profile._id}
            key={`${thread._id}_msg-${index}`}
          />
        ))}
        <div className='p-4 grid grid-cols-12 gap-4'>
          <input type='text' className='col-span-9' />
          <div className='col-span-3'>
            <button className='bg-emerald-600'>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Inbox() {
  // const { data: threads, error } = useSWR('/api/user/inbox')
  return (
    <div className='p-4'>
      {!threads ? <Spinner /> : threads.map((thread) => <Thread thread={thread} />)}
    </div>
  )
}

export const threads = [
  {
    participants: [{
      avatar: '/jenny_iEZqr_Jlu.jpg',
      username: 'trinity',
      _id: '5fdbdae334d21f184fef0d0c',
    }, {
      _id: '5fdcf52cba7f9435e5bb4664',
      avatar: '/elliot__D3ohdjea.jpg',
      username: 'neo'
    }
  ],
    messages: [{
      body: 'content of the message',
      from: '5fdbdae334d21f184fef0d0c',
      sent: '2020-12-15T13:00:00Z'
    },
    {
      body: 'pretty good message',
      from: '5fdcf52cba7f9435e5bb4664',
      sent: '2020-12-16T14:00:00Z'
    },
    {
      body: 'I thought so too actually',
      from: '5fdbdae334d21f184fef0d0c',
      sent: '2020-12-17T15:00:00Z'
    },
    {
    body: 'k well bye',
    from: '5fdcf52cba7f9435e5bb4664',
    sent: '2020-12-16T14:00:00Z'
  }]
  }
]
