import loading from 'img/loading.gif'

export default function Spinner ({ loading = true, color = 'white', classes = '' }) {
  return loading ? (
    <svg className={`animate-spin inline-block -ml-1 mr-3 h-5 w-5 text-${color} ${classes}`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
      <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
      <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
    </svg>
  ) : null
}

export const ContentLoader = ({ classes } = { classes: '' }) => (
  <div className={`animate-pulse flex ${classes}`}>
    <div className='flex-1 space-y-2 py-1'>
      <div className='h-4 bg-blue-200 rounded w-2/3'></div>
      <div className='h-4 bg-blue-200 rounded w-1/2'></div>
    </div>
    <div className='rounded-full bg-blue-200 h-12 w-12'></div>
  </div>
)

export const AppLoader = () => (
  <div className='h-full w-full'>
    <img className='h-full w-full' src={loading} alt='countdown animation' />
  </div>
)
