import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'use-http'
import { IKContext } from 'imagekitio-react'
import 'styles/index.css'
import 'styles/tw.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <Provider options={{
      interceptors: {
        response: async ({ response }) => {
          if (!response.ok) {
            const error = new Error('An error occurred while fetching the data')
            error.info = response.data
            error.status = response.status
            throw error
          }
          return response
        }
      }
    }}>
      <IKContext
        urlEndpoint='https://ik.imagekit.io/dispatchr'
        publicKey={process.env.REACT_APP_IMAGEKIT_PUB_KEY}
        transformationPosition='path'
        authenticationEndpoint='/api/upload/imagekit'
      >
        <App />
      </IKContext>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
