import { useEffect } from 'react'
import useSwr from 'swr'
import useFetch from 'use-http'
import Spinner from 'components/ui/Loaders'
import Error from 'components/ui/Error'

export default function StripeAccount () {
  const { data, error } = useSwr('/api/stripe/dashboard')
  const { post, data: connectData, loading } = useFetch('/api/stripe/connect')
  useEffect(() => {
    if (error) {
      console.log('yes error')
      post()
    }
  }, [error, post])
  if (connectData) {
    console.dir(connectData)
  }
  return (
    <div className='block px-8 pt-8 w-full'>
      <div className='border border-gray-100 rounded-md bg-gray-100 p-4 flex justify-between items-center flex-col sm:flex-row'>
        <div>
          <h2 className='font-bold text-xl'>Get paid on Dispatchr</h2>
          {
            !data && !error ?
              <p>Fetching Stripe account information...</p>
            : <div className='flex flex-row'>
              {error?.status === 400 ? 'Your Stripe account is almost ready to use' : 'Your Stripe account is connected to Dispatchr'}
              {error?.status !== 400 && <span className='inline-block ml-2 h-4 w-4'>
                <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
                </svg>
              </span>}
            </div>
          }
        </div>
        {
          !data && !error ?
            <Spinner classes='mt-2' color='gray' />
          : data?.link ? (
            <a
              href={data?.link}
              target='_blank'
              rel='noreferrer'
              className='mt-2 sm:mt-0 button cursor-pointer bg-violet-600'
            >
              Stripe Dashboard
            </a>
          ) : connectData ?
            <a
              href={connectData.url}
              rel='noreferrer'
              className='mt-2 sm:mt-0 button cursor-pointer bg-violet-600'
            >
              Complete Stripe Onboarding
            </a>
          : loading ? <Spinner /> : null
        }
      </div>
      {
        error && error.status !== 400 ?
          <>
            <Error error={error} classes='block w-full mt-4' />
          </>
        : null
      }
    </div>
  )
}
