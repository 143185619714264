import { useState } from 'react'
import useSWR from 'swr'
import MissionTile from 'components/Mission/MissionTile'
import Error from 'components/ui/Error'
import { ContentLoader } from 'components/ui/Loaders'

function Posted () {
  const { data, error } = useSWR('/api/missions/posted')
  if (error) return <Error error={error} />
  return !data ? <ContentLoader /> : (data && data.length) ?
    data.map(m => <MissionTile mission={m} context='posted' key={m._id} />) :
  'No posted missions were found'
}

function Applied () {
  const { data, error } = useSWR('/api/missions/applied')
  if (error) return <Error error={error} />
  return !data ? <ContentLoader /> : (data && data.length) ?
    data.map(m => <MissionTile mission={m} context='applied' key={m._id} />) :
  'No applied missions were found'
}

function activePanel (activeTab) {
  switch(activeTab) {
    case 'posted':
      return <Posted />
    case 'applied':
      return <Applied />
    default:
      return <Posted />
  }
}

export default function Missions () {
  const [activeTab, setActiveTab] = useState('posted')
  return (
    <div className='h-full w-screen grid grid-rows-12'>
      <nav className='w-screen row-span-1 relative z-0 inline-flex shadow-sm -space-x-px bg-white'>
        <div className={`tab w-1/2 ${activeTab === 'posted' && 'active'}`} onClick={() => setActiveTab('posted')}>
          <p className='w-full text-center font-bold'>Posted</p>
        </div>
        <div className={`tab w-1/2 ${activeTab === 'applied' && 'active'}`} onClick={() => setActiveTab('applied')}>
          <p className='w-full text-center font-bold'>Applied</p>
        </div>
      </nav>
      <div className='w-screen h-full p-4 row-span-11 overflow-y-auto overflow-x-hidden'>
        {activePanel(activeTab)}
      </div>
    </div>
  )
}
