import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

const geocoder = new MapboxGeocoder({
  accessToken: process.env.REACT_APP_MB_TOKEN,
  mapboxgl: mapboxgl,
  types: 'address, locality, place, postcode',
  minLength: 8,
})

export default function Autocomplete() {
  const { initialValues, setFieldValue } = useFormikContext()
  useEffect(() => {
    const parent = document.getElementById('location')
    if (parent.children.length === 0) {
      // check is in place as this mount repeatedly in development
      // geocoder needs a clean up function...
      // opened issue on gh repo ✅
      geocoder.addTo('#location')
      geocoder.on('result', function ({ result }) {
        setFieldValue('startLocation', result)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (initialValues.startLocation) {
      geocoder.setPlaceholder(initialValues.startLocation.place_name)
    }
  }, [initialValues])
  return <div id='location' />
}
