import { useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { SWRConfig } from 'swr'
import Div100vh from 'react-div-100vh'

import useUser from 'hooks/useUser'

import CreateModalContext from 'contexts/CreateModalContext'

import Nav from 'components/Nav'
import CreateMissionModal from 'components/form/CreateMissionModal'
import MapView from 'components/MapView'
import Missions from 'components/Missions'
import Inbox from 'components/Inbox'
import Settings from 'components/Settings'
import { AppLoader } from 'components/ui/Loaders'

const fetcher = async url => {
  const res = await fetch(url)
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data')
    error.info = await res.json()
    error.status = res.status
    throw error
  }
  return res.json()
}

function Home () {
  const [createModalOpen, toggleCreateModal] = useState(false)
  const [start, setMissionStart] = useState(false)
  const { loading, error } = useUser()
  return (
    <CreateModalContext.Provider value={{
      open: createModalOpen,
      start,
      toggleCreateModal,
      setMissionStart
    }}>
      <Router>
        <Div100vh>
          <main className='grid grid-rows-8 grid-col-1 w-screen h-full'>
            <div className='w-full h-full bg-blue-100 row-span-7'>
              {
                loading ? <AppLoader /> : error ? <Redirect to='/' /> :
                <Switch>
                  <Route path='/missions' exact component={Missions} />
                  <Route path='/inbox' exact component={Inbox} />
                  <Route path={['/settings', '/settings/:view']} exact component={Settings} />
                  <Route path='/map' exact component={MapView} />
                  <Redirect to='/map' />
                </Switch>
              }
            </div>
            <CreateMissionModal />
            <Nav />
          </main>
        </Div100vh>
      </Router>
    </CreateModalContext.Provider>
  )
}

export default function WithAuth () {
  return (
    <SWRConfig value={{ fetcher }}>
      <Home />
    </SWRConfig>
  )
}
