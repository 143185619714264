import { Link } from 'react-router-dom'
import Div100vh from 'react-div-100vh'
import logo from 'img/logo-d.png'

export default function Landing () {
  return (
    <Div100vh>
      <main className='h-full bg-pink-400 bg-opacity-70 grid grid-cols-1 sm:grid-cols-2 sm:gap-4 items-center'>
        <div className='flex flex-col items-center'>
          <img className='max-h-64 sm:max-h-96' src={logo} alt='Dispatchr Logo with text' />
        </div>
        <div className='h-full sm:h-auto'>
          <div className='flex flex-col items-center sm:items-start prose prose-xl'>
            <h3 className='pt-8'>The most <span role='img' aria-label='fire emoji'>🔥</span> app ever</h3>
            <h6 className='pb-6'>Ready to <b>win</b> the world?</h6>
            <div className='grid grid-cols-2 gap'>
              <Link to='/login'>
                <button className='bg-opacity-90 bg-emerald-600 hover:bg-emerald-700 focus:outline-none'>Login</button>
              </Link>
              <Link to='/register'>
                <button className='bg-opacity-90 bg-violet-600 hover:bg-violet-700 focus:outline-none'>Register</button>
              </Link>
            </div>
            <span className='mt-6 text-5xl' role='img' aria-label='flying money emoji'>💸</span>
          </div>
        </div>
      </main>
    </Div100vh>
  )
}
