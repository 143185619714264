import { IKImage } from 'imagekitio-react'
import ReactHtmlParser from 'react-html-parser'
import useFetch from 'use-http'
import { mutate } from 'swr'
import useUser from 'hooks/useUser'
import Spinner from 'components/ui/Loaders'
import ActiveAgent from 'components/Mission/ActiveAgent'
import MisionMarkers from 'img/markers'

function MissionApplicant ({ applicant, missionId }) {
  const { post: accept, loading: aLoading } = useFetch(`/api/missions/accept/${missionId}`)
  const { post: decline, loading: dLoading } = useFetch(`/api/missions/decline/${missionId}`)
  return (
    <div className='shadow-sm p-2 px-4 border border-gray-300 rounded shadow shadow-sm mb-2 md:w-1/2'>
      <div className='flex justify-between items-center'>
        <h2 className='text-lg font-bold'>{applicant.username}</h2>
        <IKImage className='avatar mr-2' path={applicant.avatar} alt='applicant avatar' />
      </div>
      {applicant.message ? (
        <div className='mt-2 mb-4 prose'>
          <p>
            <b>Message:</b> {applicant.message}
          </p>
        </div>
      ) : null}
      <div className='grid gap-4 grid-cols-2 md:grid-cols-4 mt-2'>
        <button
          disabled={aLoading || dLoading}
          className='md:col-start-1 md:col-end-2 button max-h-8 p-1 bg-red-500 bg-opacity-90 hover:bg-red-600'
          onClick={async () => {
            await decline({ userid: applicant._id })
            mutate('/api/missions/posted')
          }}
        >
          <Spinner loading={dLoading} />
          Decline
        </button>
        <button
          disabled={aLoading || dLoading}
          className='md:col-start-4 md:col-end-5 button max-h-8 p-1 bg-emerald-500 hover:bg-emerald-600'
          onClick={async () => {
            await accept({ userid: applicant._id })
            mutate('/api/missions/posted')
          }}
        >
          <Spinner loading={aLoading} />
          Accept
        </button>
      </div>
    </div>
  )
}

export default function MissionTile ({ mission, context }) {
  const { profile } = useUser()
  const { post, loading, response } = useFetch(`/api/missions/complete/${mission._id}`)
  return (
    <div className='bg-white shadow p-4 border border-gray-200 rounded-lg mb-4'>
      <div className='flex justify-between items-center'>
        <h3 className='text-lg leading-6 font-medium text-gray-900'>
          {mission.title}
          <button
            type='button'
            className='inline-flex items-center justify-center rounded-full bg-amber-50 text-amber-900 px-2 py-0.5 ml-2'
          >
            ${mission.bounty}
          </button>
        </h3>
        <div className='w-6 h-6'>
          {ReactHtmlParser(MisionMarkers[mission.icon || 'default'])}
        </div>
      </div>
      <p className='max-w-2xl text-md my-2 text-gray-500'>
        {mission.description}
      </p>
      {
        context === 'posted' ?
          mission.status === 'paid' ? (
            <>
              <button
                className='cursor-default bg-gray-500 rounded-full py-1 px-4'
                type='button'
              >
                Paid
              </button>
            </>
          ) : mission.activeAgent ? (
            <>
              <ActiveAgent agent={mission.activeAgent} />
              <button
                className='bg-emerald-500 w-full'
                onClick={async () => {
                  await post()
                  if (response.ok) {
                    mutate('/api/missions/posted', async missions => missions.map(m => {
                      if (m._id === response.data._id) return response.data
                      else return m
                    }))
                  }
                }}
              >
                {loading ? <Spinner /> : 'Mark Mission Completed'}
              </button>
            </>
          ) : (
            <>
              <h4 className='my-4 text-lg font-bold'>
                {mission.applicants?.length ? 'Applicants' : 'No applicants yet'}
              </h4>
              {mission.applicants?.map(applicant => (
                <MissionApplicant
                  applicant={applicant}
                  missionId={mission._id}
                  key={applicant._id}
                />
              ))}
            </>
          )
        : context === 'applied' ? (
          mission.activeAgent && mission.activeAgent !== profile._id ?
            <button className='rounded-full bg-red-400'>Application Declined</button> :
          mission.activeAgent && mission.activeAgent === profile._id ?
            <button className='rounded-full bg-emerald-400'>Active!</button> :
            <button className='rounded-full bg-blue-400'>Application Pending</button>
        ) : null
      }
    </div>
  )
}
