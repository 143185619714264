import { useState } from 'react'
import useFetch from 'use-http'
import store from 'store'
import Spinner from 'components/ui/Loaders'

export default function Account () {
  const [closing, setClosing] = useState(false)
  const { post, response } = useFetch('/api/account/close')
  return (
    <div className='block px-8 py-4 w-full'>
      <div className='card mt-2 flex flex-row justify-between w-full align-center items-center bg-amber-100'>
        <h2 className='px-4'>Danger Zone</h2>
        <button
          className='bg-red-800 hover:bg-red-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed rounded-l-none'
          type='button'
          onClick={async () => {
            if (window.confirm('Really close your account? There is no going back!')) {
              setClosing(true)
              await post()
              if (response.ok) {
                store.clearAll()
              }
            }
          }}
        >
          <Spinner loading={closing} />
          Close Account
        </button>
      </div>
    </div>
  )
}
