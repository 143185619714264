import { Link } from 'react-router-dom'
import { Form, Formik, Field } from 'formik'
import { IKUpload, IKImage } from 'imagekitio-react'
import store from 'store'
import useFetch from 'use-http'
import Div100vh from 'react-div-100vh'

import * as Yup from 'yup'
import Error from 'components/ui/Error'
import Spinner from 'components/ui/Loaders'

const schema = Yup.object().shape({
  avatar: Yup.string(),
  email: Yup.string().email('Please provide a valid email').required('Please provide an email address'),
  username: Yup.string().min(3).max(40).required('Please choose a username'),
  password: Yup.string().required(),
  password2: Yup.string().required(),
  bio: Yup.string()
})

const t = [{
  'height': '200',
  'width': '200',
}]

export default function Register () {
  const { post, response, error } = useFetch('/api/account/register')
  return (
    <Div100vh>
      <main className='h-full w-screen bg-purple-500 bg-opacity-70 flex flex-col items-center justify-center p-8 sm:p-4'>
        <Formik
          initialValues={{
            avatar: '',
            email: '',
            username: '',
            password: '',
            password2: '',
            bio: ''
          }}
          validationSchema={schema}
          onSubmit={async (values) => {
            await post(values)
            if (response.ok) {
              const { token } = response.data
              store.set('JWT_TOKEN', token)
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting, isValid }) => (
            <Form className='overflow-y-scroll flex flex-col items-center justify-evenly card h-full w-full sm:w-2/3 py-4'>
              {values.avatar ? <IKImage className='rounded-md mb-2 h-24 sm:h-40' path={values.avatar} transformation={t} /> : null}
              {!values.avatar ? <IKImage className='rounded-md mb-2 h-24 sm:h-40' path='/jenny_zc6tj_PDU.jpg' transformation={t} /> : null}
              <Field name='avatar' placeholder='Upload Avatar' type='hidden'>
                {({ field, form, meta }) => <IKUpload className='h-auto' onSuccess={(res) => {
                  form.setFieldValue('avatar', res.filePath)
                }} />}
              </Field>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:px-2'>
                <Field type='email' name='email' placeholder='Email' />
                <Field type='text' name='username' placeholder='Username' />
                <div className='sm:col-span-2'>
                  <Field className='w-full rounded-md' type='text' name='bio' placeholder='Bio (optional)' as='textarea' />
                </div>
                <Field type='password' name='password' placeholder='Password' />
                <Field type='password' name='password2' placeholder='Confirm Password' />
              </div>
              <Error classes='m-4' error={error} />
              <div className='flex flex-col w-full py-4 justify-between align-center items-center'>
                <button
                  disabled={values.password !== values.password2 || !values.password || !values.password2 || isSubmitting}
                  className='bg-rose-600 px-12 hover:bg-rose-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'
                  type='submit'
                >
                  <Spinner loading={isSubmitting} />
                  Register
                </button>
                <Link to='/login' className='text-purple-600 mt-4'>Login</Link>
              </div>
            </Form>
          )}
        </Formik>
      </main>
    </Div100vh>
  )
}
