import { useState } from 'react'
import { Form, Formik, Field } from 'formik'
import useFetch from 'use-http'
import useUser from 'hooks/useUser'
import UpdateLocation from 'components/form/UpdateLocation'
import CloseAccount from 'components/form/CloseAccount'
import StripeAccount from 'components/Account/StripeAccount'
import StripeConnect from 'components/form/StripeConnect'
import Error from 'components/ui/Error'
import Spinner, { ContentLoader } from 'components/ui/Loaders'

export default function Account () {
  const [success, setSuccess] = useState(false)
  const { profile, mutate, error: profileError } = useUser()
  const { post, error, response } = useFetch('/api/account/resetPw')
  if (profileError) return <Error error={profileError} />
  return !profile ? <ContentLoader /> : (
    <>
      {profile.stripe_account_id ? <StripeAccount /> : <StripeConnect />}
      <UpdateLocation />
      <Formik initialValues={{ pw_original: '', pw_new: '' }} onSubmit={async (values) => {
        setSuccess(false)
        await post(values)
        if (response.ok) {
          mutate()
          setSuccess(true)
        }
      }}>
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form className='block px-8 py-4 w-full'>
            <div className='border border-gray-100 rounded-md bg-gray-100 p-4'>
              <h2 className='font-bold text-xl'>Update Password</h2>
              <div className='grid grid-cols-1 sm:grid-cols-12 gap-4 py-4'>
                <Field className='sm:col-span-5' type='password' name='pw_original' placeholder='Old Password' />
                <Field className='sm:col-span-5' type='password' name='pw_new' placeholder='New Password' />
                <button
                  disabled={!values.pw_new || !values.pw_original || isSubmitting}
                  className='sm:col-span-2 bg-opacity-90 bg-violet-600 hover:bg-violet-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'
                  type='submit'
                >
                  <Spinner loading={isSubmitting} />
                  Update
                </button>
              </div>
              <Error error={error} />
              {success ? <div className='card border-2 border-emerald-400 rounded-md shadow-sm p-4 block'>Success!</div> : null}
            </div>

          </Form>
        )}
      </Formik>
      <CloseAccount />
    </>
  )
}
