import { useState } from 'react'
import { IKImage } from 'imagekitio-react'

export default function Payment ({ payment }) {
  const [show, toggle] = useState(true)
  return (
    <div className='card shadow rounded-none p-4'>
      <div className='flex justify-between'>
        <div className='flex flex-row items-center'>
          <h1 className='text-xl font-bold inline'>
            {payment.mission.title}
          </h1>
          <div>
            <button
              className='bg-blue-500 py-0 capitalize inline ml-2 rounded-full'
            >
              {payment.status}
            </button>
          </div>
        </div>
        <div className='flex flex-row'>
          <button
            type='button'
            className='inline-flex items-center justify-center rounded-full bg-amber-50 text-amber-900 px-2 py-0.5 ml-2 cursor-default'
          >
            ${payment.amount}
          </button>
          <div className='p-2 h-8 w-8 ml-1 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200'>
            <svg onClick={() => toggle(!show)} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              {
                show ?
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
                : <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M15 19l-7-7 7-7' />
              }
            </svg>
          </div>
        </div>
      </div>
      <div className={`${show ? 'inline mt-6 sm:mt-4' : 'hidden'} grid sm:grid-cols-2 gap-4`}>
        <div className='flex flex-col p-2 border w-full border-gray-200 rounded-lg'>
          <div className='flex flex-row items-center align-center justify-between'>
            <h4 className='text-lg'>Posted by <b>{payment.mission.dispatchr.username}</b></h4>
            <IKImage className='avatar mr-2' path={payment.mission.dispatchr.avatar || '/jenny_zc6tj_PDU.jpg'} />
          </div>
        </div>
        <div className='flex flex-col p-2 border w-full border-gray-200 rounded-lg'>
          <div className='flex flex-row items-center align-center justify-between'>
            <h4 className='text-lg'>Active Agent: <b>{payment.mission.activeAgent.username}</b></h4>
            <IKImage className='avatar mr-2' path={payment.mission.activeAgent.avatar || '/jenny_zc6tj_PDU.jpg'} />
          </div>
        </div>
      </div>
    </div>
  )
}
