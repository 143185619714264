import { useState } from 'react'
import useSWR from 'swr'
import CreditCardInput from 'components/Account/CreditCardInput'
import Error from 'components/ui/Error'
import { ContentLoader } from 'components/ui/Loaders'

export default function Payments () {
  const [show, toggle] = useState(true)
  const [update, setUpdate] = useState(false)
  const { data: paymentMethods, error } = useSWR('/api/user/paymentMethods')
  if (error) return <Error error={error} />

  return !paymentMethods ? <ContentLoader classes='m-4' /> : (
    <div className='p-4'>
      <div className='card shadow rounded-md p-4'>
        <div className='flex flex-row items-center justify-between mb-6'>
          <h1 className='text-lg font-bold'>Payment Methods</h1>
          {
            !!paymentMethods.length ?
              <div className='p-2 h-8 w-8 ml-1 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200'>
                <svg onClick={() => toggle(!show)} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  {
                    show ?
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
                    : <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M15 19l-7-7 7-7' />
                  }
                </svg>
              </div>
            : <span />
          }
        </div>
        {
          !!paymentMethods.length ? !toggle ? null :
          paymentMethods.map(method => (
            <div className='border border-gray-200 py-2 px-3 flex flex-row items-center justify-between rounded-sm'>
              <p className='text-gray-700 font-semibold'>
                {method.name}
                <button className='py-0 ml-2 bg-emerald-500 rounded-full cursor-default'>{method.status}</button>
              </p>
              <div className='flex justify-between min-w-12'>
                <span className='inline-block w-5 h-5 text-gray-700 cursor-pointer' onClick={() => setUpdate(true)}>
                  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z' />
                  </svg>
                </span>
                <span className='inline-block w-5 h-5 text-gray-400 cursor-disabled'>
                  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' />
                  </svg>
                </span>
              </div>
            </div>
          )) : (
            <p className='text-gray-700 font-semibold'>
              No payment methods. You won't be able to post a mission until you add one.
            </p>
          )
        }
        {
          !paymentMethods.length || update ?
            <div className='mt-6 pb-2'>
              <h4 className='text-lg font-bold'>
                Add a Credit Card
              </h4>
              <CreditCardInput />
            </div>
          : null
        }
      </div>
    </div>
  )
}
