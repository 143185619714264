import { useEffect } from 'react'
import { useHistory, BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Login from 'views/Login'
import Register from 'views/Register'
import Landing from 'views/Landing'
import StripeVerify from 'views/StripeVerify'
import Home from 'views/Home'

import useUser from 'hooks/useUser'

function PrivateRoute(props) {
  const { loggedOut } = useUser()
  const { component: Component, ...rest } = props
  const render = props => loggedOut ? <Landing /> : <Component {...props} />
  return <Route {...rest} render={render} />
}

function PublicRoute(props) {
  const { loggedOut } = useUser()
  const { component: Component, ...rest } = props
  const render = props => loggedOut ? <Component {...props} /> : <Redirect to='/' />
  return <Route {...rest} render={render} />
}

function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute path='/login' exact component={Login} />
        <PublicRoute path='/register' exact component={Register} />
        <Route path='/stripe/verify' exact component={StripeVerify} />
        <PrivateRoute path='/' component={Home} />
      </Switch>
      <AuthHandler />
    </Router>
  )
}

function AuthHandler () {
  const history = useHistory()
  const { loggedOut } = useUser()
  useEffect(() => {
    if (loggedOut) {
      history.push('/')
    }
  }, [loggedOut, history])
  return null
}

export default App
