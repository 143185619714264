import { Form, Formik, Field } from 'formik'
import useSWR from 'swr'
import useFetch from 'use-http'
import { IKUpload, IKImage } from 'imagekitio-react'
import Error from 'components/ui/Error'
import Spinner, { ContentLoader } from 'components/ui/Loaders'

const t = [{
  'height': '200',
  'width': '200',
}]

export default function Profile () {
  const { data: profile, error, mutate } = useSWR('/api/user/profile')
  const { post, response } = useFetch('/api/user/update')
  if (error) return <Error error={error} />
  return !profile ? <ContentLoader /> : (
    <Formik initialValues={{ bio: profile.bio, username: profile.username, avatar: profile.avatar }} onSubmit={async (values) => {
      await post(values)
      if (response.ok) {
        mutate()
      }
    }}>
      {({ values, setFieldValue, isSubmitting, isValid }) => (
        <Form className='block p-8 min-h-full sm:min-h-0 w-full sm:w-1/2'>
          {values.avatar ? <IKImage className='rounded-md h-24 sm:h-40' path={values.avatar} transformation={t} /> : null}
          {!values.avatar ? <IKImage className='rounded-md h-24 sm:h-40' path='/jenny_zc6tj_PDU.jpg' transformation={t} /> : null}
          <Field name='avatar' placeholder='Upload Avatar' type='hidden'>
            {({ field, form, meta }) => <IKUpload className='mt-4 mb-2' onSuccess={(res) => {
              form.setFieldValue('avatar', res.filePath)
            }} />}
          </Field>
          <div className='w-full my-4'>
            <label htmlFor='username'>Username</label>
            <Field id='username' className='block w-full sm:w-1/2' type='text' name='username' placeholder='Username' />
          </div>
          <div className='w-full my-4'>
            <label htmlFor='bio'>Bio</label>
            <Field className='rounded-md block w-full' type='text' name='bio' placeholder='Bio (optional)' as='textarea' />
          </div>
          {error && <div className='my-4 text-red-500'>{error}</div>}
          <div className='flex flex-row justify-between w-full align-center items-center my-4'>
            <button
              disabled={isSubmitting}
              className='bg-opacity-90 bg-violet-600 mr-2 hover:bg-violet-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'
              type='submit'
            >
              <Spinner loading={isSubmitting} />
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
